<template lang="">
    <div>
        <PropertyForm />
    </div>
</template>
<script>
export default {
    name:"mulk-ekle",
    components:{
        PropertyForm: () => import('@/components/properties/addproperty/propertyForm'),

    }
}
</script>
<style lang="">
    
</style>